// methods related to the manage group page

const Group = {};

// Current invite code to display
Group.inviteCode = null;

// The group name text in the UI
Group.currentGroupNameInUI = "";

// Check if dev mode is on or not
Group.isDevModeOn = function() {
	try {
		return new URLSearchParams(window.location.search).get('devmode') === 'true';
	}
	catch (error) {
		console.error("Failure when trying to get devmode from URL!", error);
		CP.showErrorMessage("An unexpected failure occureed when trying to get development mode!");
	}
	return false;
};

Group.groupIdBubble = null;

Group.showGroupID = () => {
	let encrkey = retrieveEncryptionKey()?.groupID;
	try {
		let base64 = CryptoUtil.bytesToBase64(encrkey);
		let groupIdArea = getel('groupIdArea');
		if (Group.groupIdBubble === null)
			Group.groupIdBubble = new TextBubble(groupIdArea, 'Group ID: ' + base64,
				TextBubbleStyle.Notification, null, false);
		makeElementVisible(groupIdArea);
	}
	catch (error) {
		console.error("Failure getting group id!", error);
		CP.showErrorMessage("An unexpected failure occurred when trying to get the group ID!");
	}
};

Group.hideGroupID = () => {
	let groupIdArea = getel('groupIdArea');
	if (Group.groupIdBubble !== null)
		Group.groupIdBubble.setText('', false);
	makeElementHidden(groupIdArea);
};

Group.updateGroupNameInUI = async function(linked)
{
	try {
		let groupName = '';
		if (linked)
		{
			// Get group name
			var groupInfo = await retrieveEncryptionKeyAndCheckVersion();
			var indexRes = await Cloud.getIndexFromCloud(groupInfo);

			if(!indexRes.success)
			{
				let indexObjName = Cloud.getIndexObjectName(groupInfo);
				CP.showErrorMessage("Failed to download group information from cloud ("
				+ indexObjName + ").<br />HTTP status code : " + indexRes.httpStatusCode);
			}
			else
			{
				if (indexRes.indexFile.GroupName != null)
				{
					groupName = indexRes.indexFile.GroupName;
				}
			}
			let groupNameInputUpdate = document.getElementById("groupNameInputUpdate");
			groupNameInputUpdate.value = groupName;
			Group.currentGroupNameInUI = groupName;
		}
		else
		{
			var groupNameInput = document.getElementById("groupNameInput");
			groupName = Util.getCurrentFormattedUTCDateForUI();
			groupNameInput.value = groupName;
			Group.currentGroupNameInUI = groupName;
		}
	}
	catch (error) {
		console.error('Failure to update group name in UI', error);
		CP.showErrorMessage("An unexpected error occurred while trying to update group name in the UI!");
	}
};

Group.showInviteCode = function(inviteCode)
{
	let textSpan = getel('inviteCodeText');
	textSpan.textContent = inviteCode;
};

// Update UI of manage group page
Group.updateUI = function()
{
	let copyMessageSpan = document.getElementById("CopyMessageSpan");
	makeElementHidden(copyMessageSpan);

	// Check if the group id can be retrieved from the URL
	let groupIdFromUrl = getEncryptionKeyFromUrl('groupid') === null ? false : true;

	// Check if we are linked to a group
	let linked = isLinked();

	let unlinkButton = document.getElementById("unlinkButton");
	let createNewGroupSection = document.getElementById("CreateNewGroupSection");
	let joinGroupSection = document.getElementById("JoinGroupSection");
	let linkedGroupSection = document.getElementById("LinkedGroupSection");
	let inviteCodeSection = document.getElementById("InviteCodeSection");

	setVisibility(unlinkButton, !groupIdFromUrl);
	setVisibility(createNewGroupSection, !linked);
	setVisibility(joinGroupSection, !linked);
	setVisibility(linkedGroupSection, linked);

	if (linked) {
		if (Group.isDevModeOn()) Group.showGroupID();
		else Group.hideGroupID();
		if (Group.inviteCode != null) {
			let inviteCodeSpan = document.getElementById('inviteCodeText');
			inviteCodeSpan.textContent = Group.inviteCode;
			makeElementVisible(inviteCodeSection);
		}
		else makeElementHidden(inviteCodeSection);
	} else {
		makeElementHidden(inviteCodeSection);
		Group.hideGroupID();
	}

	// Enable/disable the tabs
	getNonGroupTabLinks().forEach(link => {
		if (linked) link.classList.remove('disabled');
		else link.classList.add('disabled');
		const enabledIcon = link.querySelector('.enabled-icon');
		const disabledIcon = link.querySelector('.disabled-icon');
		if (enabledIcon) setVisibility(enabledIcon, linked);
		if (disabledIcon) setVisibility(disabledIcon, !linked);
	});

	Group.updateGroupNameInUI(linked);
};

Group.unlinkFromCloud = async function()
{
	encryptionKey = null;
	localStorage.setItem(Constants.EncryptionKeyItemName, null);
	let redirected = await logOutOfACC();
	if (!redirected) {
		CP.notify("Left Group!", false);
		Group.updateUI();
	}
};

Group.createNewGroup = async () =>
{
	let beta = isBetaInUrl();
	console.log(beta ? 'Beta program' : 'No beta program');

	var result = await Cloud.createGroup(beta ? 0 : null);
	if(result.success)
	{
		Group.inviteCode = result.inviteCode;
		var encrkey = result.encryptionKey;
		// 1 is the current cloud group version.
		// So always when we create a new group we set the version to that.
		storeEncryptionKey(encrkey, beta ? Constants.CurrentVersion : Constants.CloudSystemVersionZero);
		await Group.setGroupName(false);
		CP.notify('Created new group. Invite code: ' + result.inviteCode, false);
	}
	else
	{
		CP.showErrorMessage("Group creation failed!");
	}
	
	Group.updateUI();
};

// Create an invite code for an already existing connection
Group.createInviteCode = async () => {
	if (isLinked()) {
		let groupInfo = retrieveEncryptionKey();
		let result = await Cloud.getNewInviteCode(groupInfo.groupID,
			groupInfo.version !== null && groupInfo.version !== Constants.CloudSystemVersionZero ? 0 : null);
		if (result.success) {
			CP.notify('New invite code: ' + result.inviteCode, false);
			Group.inviteCode = result.inviteCode;
		} else {
			CP.showErrorMessage("Failed to get invite code!");
		}
		Group.updateUI();
	} else {
		// If user is not linked then leave group
		Group.unlinkFromCloud();
	}
};

Group.linkWithCloud = async () =>
{
	var keycodeInputEl = document.getElementById("keycodeInput");
	var code = keycodeInputEl.value;
	code = code.trim();

	if (Group.isDevModeOn()) {
		let groupID = convertGroupIDFromBase64(code);
		if (groupID) {
			console.log('Storing encryption key from input field: ' + code);
			//TODO see if we can get the cloud group version here
			// Maybe by trying to get index file using group id and hashed group id
			storeEncryptionKey(groupID, null);
			CP.notify("Joined group using group ID!", false);
			Group.updateUI();
		} else {
			CP.showErrorMessage("Unexpected error while processing key!");
		}
		return;
	}

	code = code.toUpperCase();
	keycodeInputEl.value = code;

	if (code.length !== 6) {
		CP.showErrorMessage('Length of invite code should be 6 letters!');
		return;
	}

	var result = await Cloud.getGroupID(code);
	if (result.success) {
		CP.notify("Joined Group!", false);
		storeEncryptionKey(result.groupID, result.version);
	} else {
		if (result.httpStatusCode === 404) CP.showErrorMessage("Code does not exist!");
		else CP.showErrorMessage("Joining group failed due to technical reasons!");
	}
	Group.updateUI();
};



Group.copyURL = async () => {
	let encrkey = retrieveEncryptionKey()?.groupID;
	if (encrkey === null) {
		let msg = "Could not retrieve the encryption key!";
		console.error(msg);
		CP.showErrorMessage(msg);
		return;
	}
	try {
		let url = `${getSiteBaseUrl()}?groupid=${getBytesAsURIComponent(encrkey)}`;
		// Copy the url to the clipboard
		// Show a message to the user about the url being copied
		if (await copyToClipboard(url)) makeElementVisible(getel("CopyMessageSpan"));
		else CP.showErrorMessage("Failed to copy text to the clipboard!");
	} catch (error) {
		console.error("Failure getting group URL!", error);
		CP.showErrorMessage("An unexpected failure occurred when trying to get the group URL!");
	}
};

// Gets group name from UI and sets it in the cloud
// Updates the UI after setting the group name
Group.setGroupName = async (update) => {

	if (!isLinked())
	{
		// If not linked then make sure the page shows that
		Group.unlinkFromCloud();
		return;
	}

	// disable group name input box and button while setting the group name
	let groupNameInputUpdate = document.getElementById('groupNameInputUpdate');
	groupNameInputUpdate.disabled = true;

	CP.notify("Setting group name.", true);
	console.log('Getting encryption key.');
	var groupInfo = await retrieveEncryptionKeyAndCheckVersion();

	let groupName = "";
	if (update)
	{
		let groupNameInputUpdate = document.getElementById('groupNameInputUpdate');
		groupName = groupNameInputUpdate.value;
	}
	else
	{
		let groupNameInput = document.getElementById('groupNameInput');
		groupName = groupNameInput.value;
	}

	console.log("Attempting to set group name to: " + groupName);

	let res = await Cloud.setGroupName(groupName, groupInfo);
	if (res.success)
	{
		CP.notify("Group name set to " + groupName + '.', false);
	}
	else
	{
		CP.showErrorMessage("Failed to set group name!");
	}

	groupNameInputUpdate.disabled = false;

	Group.updateUI();
};

// Reads the value in the group name update input box
// and if it has changed then update the group name
// in the cloud
Group.updateGroupNameIfNeeded = async function()
{
	let groupNameTextbox = document.getElementById("groupNameInputUpdate");
	let newGroupName = groupNameTextbox.value;
	if (Group.currentGroupNameInUI !== newGroupName) await Group.setGroupName(true);
};

Group.groupNameKeyDown = async function(event) {
	if (event.key === "Enter") {
		await Group.updateGroupNameIfNeeded();
	}
};

Group.groupNameBlur = async function(event) {
	await Group.updateGroupNameIfNeeded();
};

Group.DOMContentLoaded = () => {
	CP.initMsgManager(getel('errorArea'), getel('staticMessageArea'), getel('notificationArea'));
	CP.addStaticText(getel('groupPageMessage').innerHTML);

	document.getElementById("copyButtonSpan").addEventListener("click", async () => {
		CP.notify("Copying invite code", true);
		let inviteCode = getel('inviteCodeText').innerHTML;
		await copyToClipboard(inviteCode);
		CP.notify("Invite code " + inviteCode + " copied to clipboard.", false);
	});
};

Group.bodyOnLoad = function()
{
	initPage({publicPage : true});

	// For showing an error message if user clicks on one
	// of those disabled tabs when not connected to a group.
	getNonGroupTabListItems().forEach(function(item, index) {
		item.addEventListener('click', function(event) {
			if (!isLinked()) CP.showErrorMessage(
				"Create or join a cloud group to access uploads, Arkio exports, or link your ACC account.");
		});
	});

	let groupNameTextbox = document.getElementById("groupNameInputUpdate");
	// Add event listener for keydown event
	groupNameTextbox.addEventListener("keydown", Group.groupNameKeyDown);
	// Add event listener for blur event
	groupNameTextbox.addEventListener("blur", Group.groupNameBlur);

	Group.updateUI();
};
